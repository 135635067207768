<script>
import ProductCard from '@/components/ProductCard.vue';
import { addClass } from '@/helpers/text-editor-helper.js';

export default {
  components: {
    ProductCard,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    product() {
      return this.block.fields.LinkToProduct;
    },
    textContent() {
      return addClass(this.block.fields.Body, 'text-with-product');
    },
  },
};
</script>

<template>
  <div class="text-with-product__container">
    <product-card
      v-if="product"
      class="text-with-product__product"
      :product="product"
    />
    <div class="text-with-product__text-content text-editor-content" v-html="textContent">

    </div>
  </div>
</template>

<style>
  .text-with-product__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 10px;
  }

  .text-with-product__product {
    width: 153px;
    display: block;
  }

  .text-with-product__text-content {
    margin-left: auto;
    max-width: 835px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-black);
  }

  .text-with-product__text-element:first-child {
    margin-top: 0;
  }

  .text-with-product__text-element:last-child {
    margin-bottom: 0;
  }

  @media (--tabletAndDesktop) {
    .text-with-product__container {
      display: flex;
    }
  }

  /* Product Card overrides */

  .text-with-product__product .product-card__content {
    margin: 0;
  }

  .text-with-product__product .product-card__image-spacer {
    padding-top: 133.34%;
  }

  .text-with-product__product .product-card__info {
    padding: 4px 0 30px;
  }

  .text-with-product__product .product-card__name {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.015em;
  }

  .text-with-product__product .product-card__price {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.015em;
  }

  @media (--tabletAndDesktop) {
    .text-with-product__product .product-card__info {
      padding-bottom: 0;
    }
  }
</style>
